import React, { FC } from 'react';

import Link from 'components/Link';
import Wrapper from './styles';

type Props = {
  to: string;
  title: string;
  className?: string;
};

const LearnMoreBtn: FC<Props> = ({ to, title, className }) => (
  <Wrapper as={Link} className={className} to={to}>
    {title}
  </Wrapper>
);

export default LearnMoreBtn;
