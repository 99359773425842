import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img, { FixedObject } from 'gatsby-image';
import Slider from 'react-slick';

import { Title } from 'components/Common';
import { Wrapper, ImgWrapper, StyledImg } from './styles';

const sliderConf = {
  arrows: false,
  autoplay: true,
  dots: false,
  slidesToShow: 4,
  speed: 400,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

const data = graphql`
  {
    images: allFile(filter: { relativeDirectory: { eq: "images/partners" } }) {
      edges {
        node {
          name
          childImageSharp {
            fixed(quality: 75, width: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  }
`;

interface PageQueryData {
  images: {
    edges: {
      node: {
        name: string;
        childImageSharp: {
          fixed: FixedObject | FixedObject[];
        };
      };
    }[];
  };
}

type Props = {
  id: string;
};

/* eslint react/jsx-props-no-spreading: 0 */
const PartnersView: FC<Props> = ({ id }) => {
  const { images } = useStaticQuery<PageQueryData>(data);

  if (!images.edges.length) return null;

  return (
    <Wrapper id={id}>
      <Title>Nasi partnerzy</Title>
      <ImgWrapper>
        <Slider {...sliderConf}>
          {images.edges.map(({ node }) => (
            <StyledImg key={node.name}>
              <Img
                fixed={node.childImageSharp.fixed}
                alt={node.name}
                draggable={false}
              />
            </StyledImg>
          ))}
        </Slider>
      </ImgWrapper>
    </Wrapper>
  );
};

export default PartnersView;
