import styled from 'styled-components';
import { SectionWrapper } from 'components/Common';

export const Wrapper = styled(SectionWrapper)`
  margin-bottom: 13rem;

  .slick-prev,
  .slick-next {
    width: 13px;
    height: 30px;

    ::before {
      display: none;
    }
  }

  .slick-prev {
    &,
    &:active,
    &:focus,
    &:hover {
      left: -2rem;
      background: url('/icons/slider/leftArrow.svg') no-repeat;
    }
  }

  .slick-next {
    &,
    &:active,
    &:focus,
    &:hover {
      right: -2rem;
      background: url('/icons/slider/rightArrow.svg') no-repeat;
    }
  }

  .slick-dots {
    button {
      position: relative;

      ::before,
      ::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        transform: translate(-50%, -50%);
        transition: opacity 0.2s ease-in-out;
        border-radius: 50%;
        opacity: 1;
        background-color: #c7d1db;
      }

      ::after {
        opacity: 0;
        background-color: ${({ theme }) => theme.color.primary};
      }

      :hover::after {
        opacity: 0.3;
      }
    }

    .slick-active {
      button {
        ::before {
          background-color: ${({ theme }) => theme.color.primary};
        }
      }
    }
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    height: inherit;

    > div {
      height: 100%;
    }
  }
`;

export const QuoteList = styled.div`
  max-width: 995px;
  margin: 0 auto;
`;

export const QuoteItem = styled.div.attrs({
  style: {
    display: 'flex',
    width: '100%',
  },
})`
  justify-content: center;
  height: 100%;
  outline: none;
`;

export const StyledQuote = styled.div`
  max-width: 305px;
  margin: 3rem 1rem;
`;
