import styled from 'styled-components';
import { FluidObject } from 'gatsby-image';

export const DarkWrapper = styled.header`
  padding: 11.5rem 2rem 0;
  border-bottom: 8px solid ${({ theme }) => theme.color.primary};
  background: ${({ theme }) => theme.color.dark};
`;

export const HeaderWrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
  ${({ theme }) => theme.mq.tablet} {
    display: grid;
    position: relative;
    grid-template-columns: minmax(640px, 60%) 1fr;
    max-width: 1314px;
    min-height: 465px;
  }
`;

export const HeadlineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 4rem auto 0;
  ${({ theme }) => theme.mq.tablet} {
    z-index: 2;
    margin: 7.3rem 0 0 auto;
  }
`;

export const PageTitle = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 4.3rem;
  color: ${({ theme }) => theme.color.bright};
  font-family: Poppins, sans-serif;
  font-size: 3.1rem;
  font-weight: 600;
  line-height: 1.4;

  ${({ theme }) => theme.mq.tablet} {
    font-size: 3.8rem;
  }
`;

export const StyledPhoneForm = styled.form`
  width: 100%;
  max-width: 400px;
  margin-bottom: 2.7rem;
`;

export const LearnMore = styled.p`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 2px;
`;

export const LearnMoreText = styled.span`
  margin-bottom: 0.5rem;
  color: #778eaa;
`;

export const ImgWrapper = styled.div`
  transform: translateY(44px);
  ${({ theme }) => theme.mq.tablet} {
    position: absolute;
    z-index: 1;
    top: -44px;
    right: 0;
    bottom: 0;
    width: 48%;
  }
`;

interface HeroImageProps {
  fluid: FluidObject | FluidObject[];
}

export const HeroImg = styled.img.attrs(() => ({
  imgStyle: {
    objectPosition: 'center left',
  },
}))<HeroImageProps>`
  height: 100%;
  border-radius: 142px 15px;
  background-color: ${({ theme }) => theme.color.dark};
`;

export const BrightWrapper = styled.div`
  max-width: 1366px;
  margin: 18rem auto 0;
  padding: 0 2rem;
  background: url('/icons/watermark.svg') right no-repeat;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 5rem;
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 2.2rem;
  color: ${({ theme }) => theme.color.dark};
  font-size: 2rem;
  font-weight: 600;

  ::before {
    content: '';
    width: 4.65rem;
    height: 3.1rem;
    background: url('/icons/checkmarkPrimary.svg') no-repeat;
  }
`;

export const BoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 7.2rem;
`;

export const StyledBox = styled.div`
  flex: 0 1 292px;
  margin: 0 1rem 6rem;
`;
