import styled from 'styled-components';
import { FluidObject } from 'gatsby-image';

export const Wrapper = styled.section`
  display: flex;
  position: relative;
  flex-direction: column-reverse;
  max-width: 1336px;
  margin: 0 auto 7.2rem;
  padding: 2.1rem 2rem;
  border-radius: 11px;
  background: ${({ theme }) => theme.color.dark};
  color: ${({ theme }) => theme.color.bright};

  ${({ theme }) => theme.mq.tablet} {
    display: grid;
    grid-template-columns: 9fr 13fr;
    border-radius: 11px 11px 75px 11px;
    gap: 50px;
  }
`;

export const Signet = styled.img`
  display: none;
  ${({ theme }) => theme.mq.tablet} {
    display: block;
    position: absolute;
    z-index: 20;
    top: -31px;
    left: -11px;
  }
`;

interface StyledImgProps {
  fluid: FluidObject | FluidObject[];
}

export const StyledImg = styled.img<StyledImgProps>`
  max-width: 415px;
  height: 100%;
  margin: 0 auto;
  border-radius: 75px 12px 12px 75px;
  ${({ theme }) => theme.mq.tablet} {
    margin-right: 0;
  }
`;

export const StyledTitle = styled.div`
  text-align: center;
  ${({ theme }) => theme.mq.tablet} {
    text-align: left;
  }
`;
export const StyledSubtitle = styled.div`
  margin-bottom: 4rem;
  color: ${({ theme }) => theme.color.bright};
  text-align: center;
  ${({ theme }) => theme.mq.tablet} {
    text-align: left;
  }
`;

export const AdvantageList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin: 0;
  padding: 0 1rem 4rem;
  column-gap: 6rem;
  list-style: none;
  row-gap: 4rem;
`;
export const AdvantageItem = styled.li`
  font-family: Poppins, sans-serif;
  font-size: 1.5rem;
  line-height: 2.4rem;
`;
export const AdvantageHeader = styled.h3`
  margin: 0 0 0.5rem;
  font-size: 1.6rem;
  font-weight: 600;
`;
