import React, { FC } from 'react';

import { Wrapper, Text, Footer, Name } from './styles';

type Props = {
  name: string;
  occupation: string;
  text: string;
  className?: string;
};

const Quote: FC<Props> = ({ name, occupation, text, className }) => (
  <Wrapper className={className}>
    <Text>{text}</Text>
    <Footer>
      <Name>{name}</Name>
      {occupation}
    </Footer>
  </Wrapper>
);

export default Quote;
