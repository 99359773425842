import styled from 'styled-components';

export const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  padding: 1.2rem 0 2.5rem 2.1rem;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.color.dark};
  box-shadow: 0 15px 25px #0d24402b;
  color: ${({ theme }) => theme.color.bright};
`;

export const Title = styled.h3`
  margin: 0 0 1.1rem;
  padding: 0 2.1rem 4px 0;
  border-bottom: 1px solid #234874;
  font-family: Poppins, sans-serif;
  font-size: 2.6rem;
  font-weight: 600;
`;

export const Text = styled.p`
  margin: 0 0 1rem;
  padding-right: 2.1rem;
  font-size: 1.3rem;
  line-height: 1.75;
`;

export const List = styled.ul`
  margin: 0;
  padding: 0 2.1rem 2.5rem 0;
  list-style: none;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 0.7rem;
  font-size: 1.3rem;

  ::before {
    content: '';
    width: 3.1rem;
    height: 1.5rem;
    background: url('/icons/checkMark.svg') no-repeat;
  }
`;

export const BtnWrapper = styled.div`
  margin-top: auto;
`;
