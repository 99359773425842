import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import PhoneForm from 'components/Form/Phone';
import LearnMoreBtn from 'components/Button/HeroMore';
import { Subtitle, ColorPrimary } from 'components/Common';
import Box from 'components/Card/Box';
import {
  DarkWrapper,
  HeaderWrapper,
  PageTitle,
  StyledPhoneForm,
  LearnMore,
  LearnMoreText,
  ImgWrapper,
  HeroImg,
  HeadlineWrapper,
  BrightWrapper,
  BoxWrapper,
  StyledBox,
  ContentWrapper,
  List,
  Item,
} from './styles';

type Props = {
  id: string;
};

interface FormData {
  phone: number;
}

const data = graphql`
  {
    file(relativePath: { eq: "images/hero.png" }) {
      childImageSharp {
        fluid(quality: 60, maxHeight: 510, maxWidth: 650) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const HeroView: FC<Props> = ({ id }) => {
  const { file } = useStaticQuery(data);

  // TODO: replace by graphql
  const cards = [
    {
      title: 'Kredyty',
      text: 'Znajdziemy dla Ciebie odpowiedni produkt',
      list: [
        'Kredyt bez BIK',
        'Kredyt bez zdolności',
        'Kredyt bez zaświadczeń',
        'Kredyt dla spółek',
        'Kredyt konsolidacyjny',
      ],
      to: '/kredyty',
    },
    {
      title: 'Pożyczki',
      text: 'Wyszukamy dla Ciebie najlepszą pożyczkę',
      list: [
        'Pożyczka ratalna',
        'Pożyczka pomostowa',
        'Pożyczka pod zastaw nieruchomości',
      ],
      to: '/pozyczki',
    },
    {
      title: 'Leasing i Faktoring',
      text: 'Wybierzemy dla Ciebie najlepsze źródło finansowania',
      list: [
        'Leasing operacyjny',
        'Leasing finansowy',
        'Leasing zwrotny',
        'Faktoring',
      ],
      to: '/leasing-i-faktoring',
    },
  ];
  // TODO: add API
  const handlePhoneSubmit = (data: FormData) => data;

  return (
    <section>
      <DarkWrapper id={id}>
        <HeaderWrapper>
          <HeadlineWrapper>
            <PageTitle>
              Z nami żaden kredyt nie jest trudny
              <ColorPrimary>Szybko. Pewnie. Prosto.</ColorPrimary>
            </PageTitle>
            <StyledPhoneForm submit={handlePhoneSubmit} as={PhoneForm} />
            <LearnMore>
              <LearnMoreText>lub</LearnMoreText>
              <LearnMoreBtn title="Dowiedz się więcej" to="/#hero_info" />
            </LearnMore>
          </HeadlineWrapper>

          <ImgWrapper>
            <HeroImg as={Img} fluid={file.childImageSharp.fluid} />
          </ImgWrapper>
        </HeaderWrapper>
      </DarkWrapper>
      <BrightWrapper id="hero_info">
        <ContentWrapper>
          <Subtitle>
            Z zespołem <ColorPrimary>Simple Finance</ColorPrimary> to proste!
          </Subtitle>
          <List>
            <Item>Kredyty bez zdolności kredytowej</Item>
            <Item>Kredyty z opóźnieniami w płatnościach</Item>
            <Item>Trudne i niestandardowe pożyczki</Item>
          </List>
        </ContentWrapper>
        <BoxWrapper>
          {cards.map((card) => (
            <StyledBox
              as={Box}
              key={card.title}
              title={card.title}
              text={card.text}
              list={card.list}
              to={card.to}
            />
          ))}
        </BoxWrapper>
      </BrightWrapper>
    </section>
  );
};

export default HeroView;
