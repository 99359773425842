import styled from 'styled-components';

export const Wrapper = styled.a`
  position: relative;
  transition: color 0.15s ease-in;
  outline: none;
  color: ${({ theme }) => theme.color.bright};
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 1.45;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  ::after,
  ::before {
    content: '';
    position: absolute;
    right: -29px;
    width: 19px;
    height: 9px;
    transform: translateY(8px);
    transition: transform 0.15s ease-in-out, opacity 0.15s ease-in-out;
  }

  ::before {
    opacity: 1;
    background-image: url('/icons/arrowDown.svg');
  }

  ::after {
    opacity: 0;
    background-image: url('/icons/arrowDownActive.svg');
  }

  :hover,
  :focus {
    color: ${({ theme }) => theme.color.primary};

    ::before,
    ::after {
      transform: translateY(10px);
    }

    ::before {
      opacity: 0;
    }

    ::after {
      opacity: 1;
    }
  }
`;

export default Wrapper;
