import React, { FC } from 'react';
import Slider from 'react-slick';

import { Title, Subtitle } from 'components/Common';
import Quote from 'components/Quote';
import { Wrapper, QuoteList, QuoteItem, StyledQuote } from './styles';

const sliderConf = {
  dots: true,
  infinite: true,
  autoplay: false,
  arrows: true,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        slidesToShow: 1,
      },
    },
  ],
};

type Props = {
  id: string;
};

/* eslint react/jsx-props-no-spreading: 0 */
const ReferencesView: FC<Props> = ({ id }) => {
  // TODO: Query references from CMS
  const references = [
    {
      name: 'Joanna',
      occupation: '',
      text:
        'Polecam współpracę z firmą Simple Finance przede wszystkim ze względu na ich życzliwość, zaangażowanie i profesjonalne podejście do klienta. Przez prawie rok próbowałam rozwiązać problemy na własną rękę - ale w efekcie tylko przybyło mi stresów. Przełamałam się żeby nawiązać współpracę z kimś kto zna się na tym dużo lepiej ode mnie... I dzięki ostatnim czterem miesiącom intensywnej współpracy wychodzę na prostą. I śpię spokojniej.',
    },
    {
      name: 'Wojciech',
      occupation: '',
      text:
        'Ogromne podziękowania dla Pani Katarzyny – Simple Finance za profesjonalizm, cierpliwość, konkretną analizę sytuacji w moim przypadku, w celu znalezienia jak najlepszego rozwiązania i dopasowania pożyczki do moich potrzeb finansowych w danym momencie życia. Bardzo Pani i firmie dziękuję. Z czystym sumieniem polecam!',
    },
    {
      name: 'Marta',
      occupation: '',
      text:
        'Firma niezwykle przyjazna. Pomaga wyjść z problemów. Skorzystałam z ich pomocy i jestem im wdzięczna za to, że w sposób niezwykle profesjonalny pochylili się nad moją sprawą.',
    },
    {
      name: 'Mirosław',
      occupation: '',
      text:
        'Polecam firmę Simple Finance, a w szczególności współpracę z Panem Mariuszem. Wykonał dla mnie kawał dobrej roboty przy pozyskaniu kredytu dla mojej firmy. Profesjonalna obsługa!',
    },
    {
      name: 'Anna',
      occupation: '',
      text:
        'Bardzo dziękuję Panu Michałowi za profesjonalną obsługę, rzetelne doradztwo i pomoc w uzyskaniu kredytu. Wszystko na najwyższym poziomie. Polecam współpracę z firmą Simple Finance.',
    },
  ];

  return (
    <Wrapper id={id}>
      <Title>Referencje</Title>
      <Subtitle>Klienci o nas</Subtitle>
      <QuoteList>
        <Slider {...sliderConf}>
          {references.map(({ name, occupation, text }) => (
            <QuoteItem key={name}>
              <StyledQuote
                as={Quote}
                name={name}
                occupation={occupation}
                text={text}
              />
            </QuoteItem>
          ))}
        </Slider>
      </QuoteList>
    </Wrapper>
  );
};

export default ReferencesView;
