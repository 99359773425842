import styled from 'styled-components';

export const Wrapper = styled.blockquote`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 22px 20px 19px 31px;
  border-radius: 12px;
  box-shadow: 0 7px 15px #00000015;
  color: ${({ theme }) => theme.color.dark};
  font-size: 1.3rem;

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: -5px;
    width: 22px;
    height: 20px;
    background: url('/icons/quotes.svg') no-repeat;
  }
`;

export const Text = styled.p`
  margin: 0 0 3.5rem;
  line-height: 2.4rem;
  word-break: break-word;
  white-space: pre-wrap;
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  line-height: 2.4rem;
`;

export const Name = styled.span`
  font-weight: 600;
`;
