import React, { FC } from 'react';
import { PageProps } from 'gatsby';

import Head from 'views/Head';
import Hero from 'views/Hero';
import Process from 'views/Process';
import Advantages from 'views/Advantages';
import Partners from 'views/Partners';
import References from 'views/References';

const IndexPage: FC<PageProps> = () => (
  <>
    <Head />
    <Hero id="hero" />
    <Process id="process" />
    <Advantages id="advantages" />
    <Partners id="partners" />
    <References id="references" />
  </>
);

export default IndexPage;
