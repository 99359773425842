import styled from 'styled-components';

export const Wrapper = styled.form`
  position: relative;
  width: 100%;
  max-width: 403px;
`;

export const Label = styled.label`
  display: block;
  margin: 0 4px 13px;
  color: ${({ theme }) => theme.color.bright};
  font-family: Poppins, sans-serif;
  font-weight: 600;
  line-height: 1.45;
`;

export const Icon = styled.img`
  position: absolute;
  transform: translate(19px, 50%);
`;

export const Input = styled.input`
  flex-grow: 2;
  width: 100%;
  height: 46px;
  padding: 0 3.8rem;
  transition: border-color 0.15s ease-in-out;
  border: none;
  border: 3px solid ${({ theme }) => theme.color.bright};
  border-radius: 50px;
  outline: none;
  color: #99abc1;
  font-size: 1.5rem;

  ::placeholder {
    color: inherit;
  }

  :focus {
    border: 3px solid ${({ theme }) => theme.color.primary};
  }
`;

export const Submit = styled.button`
  position: absolute;
  right: 0;
  padding: 1.1rem 4.1rem;
  transition: background-color 0.2s ease-in-out;
  border: none;
  border-radius: 50px;
  outline: none;
  background: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.bright};
  font-family: Poppins, sans-serif;
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 1.45;
  cursor: pointer;

  :hover,
  :focus,
  :active {
    background: ${({ theme }) => theme.color.primaryDark};
  }
`;

export const Error = styled.span`
  position: absolute;
  bottom: -25px;
  left: 20px;
  color: ${({ theme }) => theme.color.inputError};
  font-size: 1.3rem;

  ::before {
    content: '⚠ ';
    display: inline;
  }
`;
