import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import regex from 'utils/regex';

import { Wrapper, Icon, Label, Input, Submit, Error } from './styles';

type Props = {
  submit: (data: FormData) => void;
  className?: string;
};

interface FormData {
  phone: number;
}

const PhoneForm: FC<Props> = ({ submit, className }) => {
  const { register, handleSubmit, errors, reset } = useForm();

  const onSubmit = (data: FormData) => {
    reset();
    submit(data);
  };

  return (
    <Wrapper className={className} onSubmit={handleSubmit(onSubmit)}>
      <Label htmlFor="phone">Zostaw swój nr telefonu, a oddzwonimy</Label>
      <Icon src="/icons/mobile.svg" />
      <Input
        ref={register({
          required: true,
          maxLength: 12,
          pattern: regex.phone,
        })}
        id="phone"
        type="number"
        name="phone"
        placeholder="+48"
        inputMode="tel"
      />
      <Submit type="submit">Wyślij</Submit>
      {errors.phone && (
        <Error>
          {errors.phone.type === 'pattern'
            ? 'Podany numer jest nieprawidłowy'
            : 'Numer telefonu jest wymagany'}
        </Error>
      )}
    </Wrapper>
  );
};

export default PhoneForm;
