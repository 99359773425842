import React, { FC } from 'react';

import { Title, Subtitle } from 'components/Common';
import ActionBtn from 'components/Button/Action';
import { Wrapper, StepList, StepItem, StepImgs, BtnWrapper } from './styles';

type Props = {
  id: string;
};
const ProcessView: FC<Props> = ({ id }) => {
  const steps = [
    {
      img: 'first.svg',
      img2: 'firstArrow.svg',
      text: 'Kontaktujemy się z Tobą',
    },
    {
      img: 'second.svg',
      img2: 'secondArrow.svg',
      text: 'Wybieramy najlepsze rozwiązanie',
    },
    {
      img: 'third.svg',
      img2: 'thirdArrow.svg',
      text: 'Wspieramy w złożeniu wniosku',
    },
    {
      img: 'final.svg',
      img2: null,
      text: 'Po pozytywnej decyzji finalizujemy współpracę',
    },
  ];

  return (
    <Wrapper id={id}>
      <Title>Nasz proces</Title>
      <Subtitle>Jak działamy</Subtitle>
      <StepList>
        {steps.map(({ img, img2, text }) => (
          <StepItem key={text}>
            <StepImgs>
              <img src={`/icons/process/${img}`} alt="" />
              {img2 && <img src={`/icons/process/${img2}`} alt="" />}
            </StepImgs>
            {text}
          </StepItem>
        ))}
      </StepList>
      <BtnWrapper>
        <ActionBtn title="Umów rozmowę z ekspertem" to="/#contact" />
      </BtnWrapper>
    </Wrapper>
  );
};

export default ProcessView;
