import styled from 'styled-components';

export const Wrapper = styled.section`
  padding: 0 2rem 6rem;
`;

export const ImgWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  cursor: pointer;
`;

export const StyledImg = styled.div.attrs({
  style: {
    display: 'flex',
    width: '100%',
  },
})`
  align-items: center;
  justify-content: center;
  height: 100px;
  outline: none;
`;
