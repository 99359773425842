import React, { FC } from 'react';

import LearnMore from 'components/Button/LearnMore';
import { Wrapper, Title, Text, List, Item, BtnWrapper } from './styles';

type Props = {
  title: string;
  text: string;
  list: string[];
  to: string;
  className?: string;
};

const BoxCard: FC<Props> = ({ title, text, list, to, className }) => (
  <Wrapper className={className}>
    <Title>{title}</Title>
    <Text>{text}</Text>
    <List>
      {list.map((item) => (
        <Item key={item}>{item}</Item>
      ))}
    </List>
    <BtnWrapper>
      <LearnMore title="Dowiedz się więcej" to={to} />
    </BtnWrapper>
  </Wrapper>
);

export default BoxCard;
