import styled from 'styled-components';

export const Wrapper = styled.a`
  display: inline-flex;
  align-items: center;
  outline: none;
  color: ${({ theme }) => theme.color.primary};
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.4rem;
  text-decoration: none;
  white-space: nowrap;

  ::after {
    content: '';
    width: 14px;
    height: 13px;
    transform: translateX(1rem);
    transition: transform 0.15s ease-in-out;
    background: url('/icons/arrowRight.svg') no-repeat;
  }

  :hover,
  :focus {
    ::after {
      transform: translateX(1.4rem);
    }
  }
`;

export default Wrapper;
