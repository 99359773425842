import styled from 'styled-components';

export const Wrapper = styled.section`
  margin: 0 0 12.5rem;
`;

export const StepList = styled.ol`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 2rem;
  list-style: none;
`;

export const StepItem = styled.li`
  flex: 1 0 220px;
  max-width: 260px;
  margin: 0 1rem 4rem;
  color: ${({ theme }) => theme.color.dark};
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.4rem;
`;

export const StepImgs = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  height: 74px;
  margin-bottom: 1.5rem;
`;

export const BtnWrapper = styled.div`
  text-align: center;
`;
