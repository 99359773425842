import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import { Title, Subtitle } from 'components/Common';
import {
  Wrapper,
  Signet,
  StyledImg,
  StyledTitle,
  StyledSubtitle,
  AdvantageList,
  AdvantageItem,
  AdvantageHeader,
} from './styles';

const data = graphql`
  {
    file(relativePath: { eq: "images/advantages.png" }) {
      childImageSharp {
        fluid(quality: 75, maxHeight: 505, maxWidth: 415) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

type Props = {
  id: string;
};
const AdvantagesView: FC<Props> = ({ id }) => {
  const { file } = useStaticQuery(data);

  const advantages = [
    {
      title: 'Szybkie decyzje',
      text:
        'Szybko i skutecznie załatwimy Twoją sprawę, bo znamy rynki finansowe. Wiemy, że czas to pieniądz!',
    },
    {
      title: 'Proste zasady',
      text:
        'Nie musisz wychodzić z domu by z nami współpracować. Cenimy Twój czas',
    },
    {
      title: 'Odpowiedzialność',
      text:
        'Zapewniamy Ci dyspozycyjność i profesjonalną opiekę naszego zespołu. Twoje bezpieczeństwo jest dla nas najważniejsze.',
    },
    {
      title: 'Zadowolenie klientów',
      text:
        'Gwarantujemy, że zrobimy wszystko by osiągnąć Twój cel. Twój sukces to nasz sukces!',
    },
  ];

  return (
    <Wrapper id={id}>
      <Signet src="/icons/signet.svg" alt="" />

      <div>
        <StyledImg as={Img} fluid={file.childImageSharp.fluid} />
      </div>

      <div>
        <StyledTitle as={Title}>Simple Finance</StyledTitle>
        <StyledSubtitle as={Subtitle} bright>
          Co nas wyróżnia
        </StyledSubtitle>

        <AdvantageList>
          {advantages.map(({ title, text }) => (
            <AdvantageItem key={title}>
              <AdvantageHeader>{title}</AdvantageHeader>
              {text}
            </AdvantageItem>
          ))}
        </AdvantageList>
      </div>
    </Wrapper>
  );
};

export default AdvantagesView;
